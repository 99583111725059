import { RELATIONS_DATA } from './data';
export default function Result2({
  relationship
}) {
  const index = Object.keys(RELATIONS_DATA).filter(item => item.indexOf(relationship) > -1)

  return <>
    <div className="dabiaoti2">
      <strong>{index}</strong>
    </div>
    <div className="neirong" dangerouslySetInnerHTML={{__html: RELATIONS_DATA[index]}}></div>
  </>
}
