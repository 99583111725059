import {useState} from 'react';
import { LunarUtil, Lunar, Solar } from 'lunar-javascript';
import { DownFill } from 'antd-mobile-icons';

import {
  CascadePickerView,
  DatePickerView,
  PickerView,
  Popup,
  Tabs,
  Space,
  Toast,
  Button,
} from 'antd-mobile';
import {
  getTimeList,
  getLunarBirthday,
  convertTime,
  getMinYear,
  addHourTime,
  formatDate,
  CreateLunarYear,
  transformSolarDate,
} from '@/common/utils';


const now = new Date();
const CURRENT_YEAR = now.getFullYear();
let MIN_YEAR = getMinYear(-82);
let MAX_YEAR = getMinYear(10);


function getLunarYear() {
  return new CreateLunarYear(MIN_YEAR, MAX_YEAR).init();
}

export default function BirthSelect({
  isSelf,
  solarBirth,
  setSolarBirth,
  sex,
  setSex,
  partnerSex,
  setPartnerSex,
}) {
  const [visible, setVisible] = useState(false);
  const [solarDate, setSolarDate] = useState(now);
  const [activeKey, setActiveKey] = useState('solarYear');
  const l = Lunar.fromDate(new Date(solarDate.getFullYear(), solarDate.getMonth(), solarDate.getDate()));
  const [value1, setValue1] = useState([l.getYear(), l.getMonth(), l.getDay()]);


  const handleBirthDate = (val) => {
    console.log('onChange', val);
    setSolarDate(val);
    const dd = Lunar.fromDate(new Date(val.getFullYear(), val.getMonth(), val.getDate()));
    setValue1([dd.getYear(), dd.getMonth(), dd.getDay()]);
  }

  const handleLunarBirthDate = (val) => {
    setValue1(val);
    console.log('handleLunarBirthDate onChange', val);
    const [year, month, day] = transformSolarDate([val[0], val[1], val[2]]);
    setSolarDate(new Date(year, month - 1, day));
  }

  const showBirthTime = (type) => {
    // 判断当前标签是阳历还是阴历
    let d = null;
    if (activeKey === 'lunarYear') {
      const [year, month, day] = transformSolarDate(value1);
      d = new Date(year, month - 1, day);
      setSolarDate(d);
    }

    const solarBirth = activeKey === 'lunarYear' ? d : solarDate;
    setSolarBirth(solarBirth);
    setVisible(false);
  }


  return (<><div className="form-input">
    <span
      style={{ 'color': solarBirth === '' ? '#808080' : '#101010'}}
      onClick={() => {
      setVisible(true)
      }}>{solarBirth === '' ? {
    0:  "请输入您的出生日期" ,
    1: "请输入对方的出生日期"
  }[isSelf]: `${{
    0:  "您的生日" ,
    1: "对方生日"
  }[isSelf]}: 公历 ${formatDate(solarBirth, 0)} ${{
    0: sex,
    1: partnerSex,
  }[isSelf]}`}<i><DownFill style={ { 'right': '0', color: '#ccc' }} /></i></span>
  </div>
  <Popup
    visible={visible}
    onMaskClick={() => {
      setVisible(false)
    }}
    bodyStyle={{ height: '50vh' }}
  >
    <Tabs
      activeKey={activeKey}
      style={{
        '--active-line-color': '#ff8f1f',
        '--active-title-color': '#ff8f1f',
      }}
      onChange={(key) => {
        setActiveKey(key);
      }}
    >
      <Tabs.Tab title='公历' key='solarYear'>
        <div className="time-picker">
          <DatePickerView
            min={new Date(MIN_YEAR)}
            max={new Date(MAX_YEAR)}
            defaultValue={now}
            value={solarDate}
            onChange={(val, extend) => handleBirthDate(val)}
            precision='day'
            style={{ '--height': '170px' }} />
            <PickerView
            style={{ '--height': '170px' }}
            onChange={(val, extend) => isSelf === 0 ? setSex(val) : setPartnerSex(val)}
            columns={[
              [
                { value: '男', label: '男' },
                { value: '女', label: '女' },
              ],
            ]} />
        </div>
      </Tabs.Tab>
      <Tabs.Tab title='农历' key='lunarYear'>
        <div className="time-picker">
          <CascadePickerView
            options={getLunarYear()}
            value={value1}
            style={{ '--height': '170px' }}
            onChange={val => handleLunarBirthDate(val)}
          />
          <PickerView
            style={{ '--height': '170px' }}
            onChange={(val, extend) => isSelf === 0 ? setSex(val) : setPartnerSex(val)}
            columns={[
              [
                { value: '男', label: '男' },
                { value: '女', label: '女' },
              ],
            ]} />
        </div>
      </Tabs.Tab>
    </Tabs>
    <div className="confirm-button">
      <Space style={{ '--gap': '24px' }}>
        <Button
          onClick={() => {
            setVisible(false)
          }}
        >
          取消
        </Button>
        <Button color="warning"
          onClick={() => showBirthTime()}
        >确定</Button></Space>
    </div>
  </Popup>
  </>
  )
}