import { useState, useEffect } from 'react';
import { Lunar, Foto } from 'lunar-javascript';
import MingPan from './MingPan';
import Result1 from './Result1';
import Result2 from './Result2';
// import { poster } from './data';

export default function XingXiu({
  birth1,
  birth2,
  sex,
  partnerSex,
}) {
  const [data1, setData1] = useState(null);
  const [data2, setData2] = useState(null);
  const [relationship, setRelationship] = useState(null);

  useEffect(() => {
    console.log(birth1, birth2);
    // var d = Lunar.fromDate(new Date());
    // console.log(new Date())
    // console.log(d.getXiu());
    // console.log(d.getAnimal());
    // console.log(d.getXiuLuck());
    // console.log(d.getXiuSong());
    var d1 = Foto.fromLunar(Lunar.fromDate(birth1));
    setData1(d1);
    if (birth2) {
      var d2 = Foto.fromLunar(Lunar.fromDate(birth2));
      setData2(d2);
    }
  }, []);

  if (!data1) {
    return null;
  }

  return (
    <div id="main_content">
      <div id="jieguo_show">
        <div className="ju_zhong">
          【公历：{birth1.getFullYear()}年{birth1.getMonth() + 1}月{birth1.getDate()}日 出生的人所属星宿】
        </div>
        
        {birth2 ? <><h1 className="dabiaoti">{data1.getXiu()}宿 与 {data2.getXiu()}宿 的关系</h1>
        <div className="huisefengexian"></div>
        <table className="ju_zhong" width="100%" border="0" cellPadding="0" cellSpacing="0">
          <tbody><tr>
            {/* <td><span><img src={`https://maimaimai.xyz/images/${poster[data1.getXiu()]}.jpg`} width="90%" style={{ maxWidth: "180px" }} className="img_yuanjiao" /></span></td> */}
            {/* <td rowSpan="2">与</td> */}
            {/* <td><span><img src={`https://maimaimai.xyz/images/${poster[data2.getXiu()]}.jpg`} width="90%" style={{ maxWidth: "180px" }} className="img_yuanjiao" /></span></td> */}
          </tr>
            <tr>
              <td>你的星宿：<span style={{
                fontWeight: 700,
                fontSize: 14,
              }}>{data1.getXiu()}宿({sex})</span></td>
              <td>对方星宿：<span style={{
                fontWeight: 700,
                fontSize: 14,
              }}>{data2.getXiu()}宿({partnerSex})</span></td>
            </tr>
          </tbody></table></>: <><h1 className="dabiaoti">{data1.getXiu()}宿（{data1.getXiu()}{data1.getZheng()}{data1.getAnimal()}）</h1>
        <div className="huisefengexian"></div>
        <div className="neirong">
          <div className="ju_zhong">
            {/* <img src={`https://maimaimai.xyz/images/${poster[data1.getXiu()]}.jpg`} width="180" height="180" className="img_yuanjiao" /> */}
          </div>
          <div className="ju_zhong">
            『{data1.getXiu()}宿』{data1.getXiuSong()}
          </div>
        </div></>}
        

        <div className="dabiaoti2">
          <strong>{data1.getXiu()}宿（{data1.getXiu()}{data1.getZheng()}{data1.getAnimal()}）的星宿命盘</strong>
        </div>
        <div>
          <MingPan target1={data1.getXiu()} target2={data2 ? data2.getXiu() : ''} setRelationship={setRelationship}/>
        </div>

        <div className="ju_zhong pmk_sj_show">
          用手指左右滑动查看全部
        </div>
        
        {
          birth2 ? <Result2 relationship={relationship} /> : <Result1 star={data1.getXiu()} animal={`${data1.getXiu()}${data1.getZheng()}${data1.getAnimal()}`} />
        }
      </div></div>
  );
}
