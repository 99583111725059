
function rotateArrayUntilCenter(starNames, target) {
  // 确定目标星宿在数组中的初始索引
  let targetIndex = starNames.indexOf(target);

  // 循环直到"心"星宿位于数组的中间位置
  while (targetIndex !== 13) { // 13是数组长度28的中间索引
    // 将数组的最后一个元素移动到数组的开头
    starNames.unshift(starNames.pop());

    // 更新"心"星宿的新索引
    targetIndex = starNames.indexOf(target);
  }

  return starNames;
}

const starNames = [
  '心', '房', '氐', '亢', '角', 
  '轸', '翼', '张', '星', '柳', 
  '鬼', '井', '参', '觜', '毕', 
  '昴', '胃', '娄', '奎', '壁', 
  '室', '危', '虚', '女', '斗', 
  '箕', '尾'
];

const starHostRelationships = [
  { className: "blue", title: "危星", text: "危" },
  { className: "red", title: "安住星", text: "安" },
  { className: "blue", title: "衰星", text: "衰" },
  { className: "red", title: "荣星", text: "荣" },
  { className: "red", title: "业星", text: "业" },
  { className: "blue", title: "亲星", text: "亲" },
  { className: "red", title: "友星", text: "友" },
  { className: "blue", title: "破坏星", text: "坏" },
  { className: "red", title: "成星", text: "成" },
  { className: "blue", title: "危星", text: "危" },
  { className: "red", title: "安住星", text: "安" },
  { className: "blue", title: "衰星", text: "衰" },
  { className: "red", title: "荣星", text: "荣" },
  { className: "green", title: "命之星", text: "命" },
  { className: "blue", title: "亲星", text: "亲" },
  { className: "red", title: "友星", text: "友" },
  { className: "blue", title: "破坏星", text: "坏" },
  { className: "red", title: "成星", text: "成" },
  { className: "blue", title: "危星", text: "危" },
  { className: "red", title: "安住星", text: "安" },
  { className: "blue", title: "衰星", text: "衰" },
  { className: "red", title: "荣星", text: "荣" },
  { className: "blue", title: "胎星", text: "胎" },
  { className: "blue", title: "亲星", text: "亲" },
  { className: "red", title: "友星", text: "友" },
  { className: "blue", title: "破坏星", text: "坏" },
  { className: "red", title: "成星", text: "成" },
];

export default function MingPan({
  target1 = '',
  target2 = '',
  setRelationship,
}) {
  const rotatedStarNames = rotateArrayUntilCenter(starNames, target1);
  const activeIndex = rotatedStarNames.findIndex(item => item === target1);

  let relationshipIndex = -1;
  if (target2) {
    relationshipIndex =  rotatedStarNames.findIndex(item => item === target2);
  }


  return (
    <table style={{
      width: '98%',
      display: 'block',
      margin: '0 auto', // 这将设置上边距和下边距为0，左右边距为auto
      overflowX: 'scroll'
    }} width="100%" className="ju_zhong" border="0" cellPadding="0" cellSpacing="0" bgcolor="#DEDFE1" align="center">
      <tbody>
        <tr>
          <td>
            <table width="100%" border="0" cellPadding="4" cellSpacing="1">
              <tbody>
              <tr>
                {rotatedStarNames.map((star, index) => (
                  <td key={index} bgcolor={(star === target1 || index === relationshipIndex) ? '#f2d39b': "#FFFFFF"}>
                    <a href={`/20_${index + 1}__xingxiupd/`} title="Some title">{star}</a>
                  </td>
                ))}
              </tr>
                <tr>
                  <td colSpan="4" bgcolor="#FFFFFF">◇</td>
                  <td bgcolor="#FFFFFF"> </td>
                  <td colSpan="8" bgcolor="#FFFFFF">◆　　　　◇</td>
                  <td bgcolor="#FFFFFF">↑</td>
                  <td colSpan="8" bgcolor="#FFFFFF">◆　　　　◇</td>
                  <td bgcolor="#FFFFFF"> </td>
                  <td colSpan="4" bgcolor="#FFFFFF">◆</td>
                </tr>
                <tr>
              {starHostRelationships.map((relationship, index) => {
                if ((index === relationshipIndex)) {
                  setRelationship(relationship.text)
                }
                return (
                <td key={index} bgcolor={(index === activeIndex || index === relationshipIndex) ? '#f2d39b':"#FFFFFF"} className={relationship.className} title={relationship.title}>
                  {relationship.text}
                </td>
              )
              })}
            </tr>
                <tr>
                  <td colSpan="4" bgcolor="#FFFFFF">远距离</td>
                  <td bgcolor="#FFFFFF"> </td>
                  <td colSpan="4" bgcolor="#FFFFFF">中距离</td>
                  <td colSpan="4" bgcolor="#FFFFFF">近距离</td>
                  <td bgcolor="#FFFFFF"> </td>
                  <td colSpan="4" bgcolor="#FFFFFF">近距离</td>
                  <td colSpan="4" bgcolor="#FFFFFF">中距离</td>
                  <td bgcolor="#FFFFFF"> </td>
                  <td colSpan="4" bgcolor="#FFFFFF">远距离</td>
                </tr>
              </tbody>
            </table> </td>
        </tr>
      </tbody>
    </table>
  )
}