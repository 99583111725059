import { Solar, SolarYear, Lunar, LunarUtil, LunarYear } from 'lunar-javascript';

function getTimeList() {
  var d = Lunar.fromDate(new Date());
  const amPmColumns = [];
  // 获取当天时辰列表
  var times = d.getTimes();
  for (var i = 0, j = times.length; i < j; i++) {
    var time = times[i];
    amPmColumns.push({
      value: time.getMinHm(),
      label: `${time.getMinHm()}~${time.getMaxHm()}(${time.getGanZhi().substr(1)}时)`,
    });
  }

  return amPmColumns;
}

function formatNumber(num) {
  return num > 9 ? num : `0${num}`;
}

/** 转换时间 子时等 */
function convertTime(date) {
  return LunarUtil.convertTime(`${formatNumber(date.getHours())}:${formatNumber(date.getMinutes())}`);
}

/** 阳历转阴历 */
function getLunarBirthday(date) {
  var solar = Solar.fromDate(date);

  // 转阴历
  const lunar = solar.getLunar();

  return lunar;
}

/** 设置最小的年 */
function getMinYear(prev = -82) {
  var d1 = SolarYear.fromDate(new Date());
  return d1.next(prev).toString();
}

/** 年月日+时间 */
function addHourTime(time, add) {
  return new Date(time.setHours(add));
}

/** 输出xxxx年xx月xx日x时 */
function formatDate(date, convert) {
  console.log(50, date.getFullYear());
  return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`;
}

class CreateLunarYear {
  constructor(MIN_YEAR, MAX_YEAR) {
    this.DAY = ['', '初一', '初二', '初三', '初四', '初五', '初六', '初七', '初八', '初九', '初十', '十一', '十二', '十三', '十四', '十五', '十六', '十七', '十八', '十九', '二十', '廿一', '廿二', '廿三', '廿四', '廿五', '廿六', '廿七', '廿八', '廿九', '三十'];
    this.MIN = transformLunarDate([MIN_YEAR, 1, 1]);
    this.MAX = transformLunarDate([MAX_YEAR, 1, 1]);
  }

  init() {
    console.log(this.MIN, this.MAX);
    const years = [];

    for (let i = Number(this.MIN[0]); i <= this.MAX[0]; i++) {
      years.push({
        label: i,
        value: i,
        children: this.setMonths(i),
      });
    }

    return years;
  }

  setMonths(year) {
    let lunarYear = LunarYear.fromYear(year);
    let months = lunarYear.getMonths();
    let m = [];

    for (var i = 0, j = months.length; i < j; i++) {
      const fullYear = months[i].toString();
      let y = fullYear.split('年')[0];
      let month = fullYear.split('月')[0].split('年')[1] + '月';
      let daycount = months[i].getDayCount();

      if (Number(y) === year) {
        m.push(
          {
            label: month,
            value: months[i].getMonth(),
            children: this.setDay(daycount),
          }
        )
      }
    }

    return m;
  }

  setDay(daycount) {
    const day = [];
    for(let i = 0; i< daycount; i++) {
      day.push({
        label: this.DAY[i + 1],
        value: i + 1,
      });
    }
    return day;
  }
}


// 转阳历
function transformSolarDate([year, month, day]) {
  // 实例化
  let lunar = Lunar.fromYmd(year, month, day);

  // 转阳历
  let solar = lunar.getSolar();

  return [solar.getYear(), solar.getMonth(), solar.getDay()];
}


// 转阴历
function transformLunarDate([year, month, day]) {
  // 实例化
  var solar = Solar.fromYmd(year, month, day);

  // 转阴历
  var lunar = solar.getLunar();

  return [lunar.getYear(), lunar.getMonth(), lunar.getDay()]
}

export {
  getTimeList,
  getLunarBirthday,
  convertTime,
  getMinYear,
  addHourTime,
  formatDate,
  CreateLunarYear,
  transformSolarDate,
  transformLunarDate,
}