
{/* 联系老师 */}
export default function Contact({
  qrcode,
  buttonConcat,
  showContact,
  qrcode2,
  closeShowContact,
}) {
  return (
    <div className="group6">
    <div className="contact-button" onClick={() => handleContact()}>
      <img src={buttonConcat} />
    </div>
    {
        showContact &&
        <div className="contact-popup">
        <div className="popup-mask"></div>
        <div className="popup-content">
          <h3></h3>
          <p></p>
          <div className="item-qrcode">
            <img src={qrcode} />
          </div>
          <p>扫二维码</p>
          <p>领取体验课</p>
          <div className="icon-close" onClick={() =>closeShowContact()}></div>
        </div>
      </div>
    }
    <div className="grid">
        <div className="item-left">
          <div className="item-qrcode">
            <img src={qrcode2} />
            <span>关注公众号</span>
            <span>每日公益直播课堂</span>
          </div>
        </div>
        <div className="item-right">
          <h3>何泳慧</h3>
          <span>国学传承学者，20年深入研究</span>
          <span>擅长：八字命理、玄空风水</span>
          <span>独创五行平衡好运算法「破命局」</span>
        </div>
      </div>
  </div>
  )
}