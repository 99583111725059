import { useState } from 'react';

import {
  Toast,
} from 'antd-mobile';

import banner from './assets/bg-banner.png';
import logo from './assets/icon-logo.png';
import qrcode from './assets/qrcode.png';
import qrcode2 from './assets/qrcode2.jpeg';
import buttonConcat from './assets/button-lxls.png';
import XingXiu from './XingXiu';
import Contact from './Contact';
import './App.less'
import BirthSelect from './BirthSelect';

function App() {
  const [page, setPage] = useState(1);
  // const [name, setName] = useState('');
  // const [sex, setSex] = useState('男');
  const [type, setType] = useState(0);// 0 测星宿 1 测关系
  const [showContact, setShowContact] = useState(false);

  const [solarBirth, setSolarBirth] = useState('');
  const [solarBirth2, setSolarBirth2] = useState('');

  const [sex, setSex] = useState('男'); // 0 男 1 女 00 男 01 女
  const [partnerSex, setPartnerSex] = useState('男'); // 0 男 1 女 00 男 01 女


  // const handleSex = (e) => {
  //   setSex(e.target.value.split(' ')[1]);
  // }

  const handleType = (e) => {
    setType(e);
  }

  const handleContact = () => {
    setShowContact(true);
  }

  const closeShowContact = () => {
    setShowContact(false);
  }

  const handleForm = () => {
    if (solarBirth === '' && sex === '') {
      Toast.show({
        content: '请输入您的生日',
        afterClose: () => {
          console.log('after')
        },
      });
      return;
    }

    if (type === 1 && solarBirth2 === '' &&  partnerSex === '') {
      Toast.show({
        content: '请输入对方生日',
        afterClose: () => {
          console.log('after')
        },
      });
      return;
    }

    setPage(3);
  }

  return (
    <>
      <div className={`page1${page === 1 ? '' : ' fn-hide'}`}>
        <div className="banner">
          <img src={logo} style={{
            width: '200px',
            paddingTop: '40px'
          }}/>
          <img src={banner} />
        </div>
        <div className="form">
          <div className="form-title">
            通过星宿测算，快速帮您了解事业、健康、婚姻、子女财运等运势
          </div>
          <div className="form-content">
          <div className="form-tab">
              <input className={`${type === 0 ? 'active' : ''}`} type="button" value="测星宿" onClick={(e) => handleType(0)} />
              <input className={`${type === 1 ? 'active' : ''}`} type="button" value="测星宿关系" onClick={(e) => handleType(1)} />
            </div>
            <BirthSelect
              isSelf={0}
              solarBirth={solarBirth}
              setSolarBirth={setSolarBirth}
              sex={sex}
              setSex={setSex}
              partnerSex={partnerSex}
              setPartnerSex={setPartnerSex}
            />
            {type === 1 && <BirthSelect
              isSelf={1}
              solarBirth={solarBirth2}
              setSolarBirth={setSolarBirth2}
              sex={sex}
              setSex={setSex}
              partnerSex={partnerSex}
              setPartnerSex={setPartnerSex}
            />}
            <div className="form-submit" onClick={() => handleForm()}>立即测算</div>
          </div>
        </div>
      </div>
      {
        page === 3 && <div className={`page2${page === 3 ? '' : ' fn-hide'}`}>
          <XingXiu sex={sex} partnerSex={partnerSex} birth1={solarBirth} birth2={type === 1 &&  solarBirth2 ? solarBirth2 : ''}/>
          <Contact {
            ...{
              qrcode,
              buttonConcat,
              showContact,
              qrcode2,
              closeShowContact,
            }
          }/>
        </div>
      }
    </>
  )
}

export default App
