import { ALL_RESULT } from './data';

export default function Result1({
  star,
  animal
}) {
  return (
    <>
    <div className="dabiaoti2">
          <strong>{star}宿（{animal}）的意义</strong>
        </div>
        <div className="neirong" dangerouslySetInnerHTML={{__html: ALL_RESULT[star].significance}}></div>
        <div className="dabiaoti2">
          <strong>{star}宿（{animal}）的本命</strong>
        </div>
        <div className="neirong" dangerouslySetInnerHTML={{__html: ALL_RESULT[star].fate}}></div>
        <div className="dabiaoti2">
          <strong>{star}宿（{animal}）的一生命运</strong>
        </div>
        <div className="neirong" dangerouslySetInnerHTML={{__html: ALL_RESULT[star].lifeFortune}}></div>
        <div className="dabiaoti2">
          <strong>{star}宿（{animal}）的性格分析</strong>
        </div>
        <div className="neirong" dangerouslySetInnerHTML={{__html: ALL_RESULT[star].personality}}></div>
        <div className="dabiaoti2">
          <strong>{star}宿（{animal}）的事业分析</strong>
        </div>
        <div className="neirong" dangerouslySetInnerHTML={{__html: ALL_RESULT[star].career}}></div>
        <div className="dabiaoti2">
          <strong>{star}宿（{animal}）的财运分析</strong>
        </div>
        <div className="neirong" dangerouslySetInnerHTML={{__html: ALL_RESULT[star].wealth}}></div>
        <div className="dabiaoti2">
          <strong>{star}宿（{animal}）的爱情婚姻</strong>
        </div>
        <div className="neirong" dangerouslySetInnerHTML={{__html: ALL_RESULT[star].loveMarriage}}></div>
        <div className="dabiaoti2">
          <strong>{star}宿（{animal}）的健康养生</strong>
        </div>
        <div className="neirong" dangerouslySetInnerHTML={{__html: ALL_RESULT[star].health}}></div>
        </>
  )
}